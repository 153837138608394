export const MIGRATION_ERROR = {
  NO_PAGE: 'NO_PAGE',
  NO_CARD: 'NO_CARD',
};

export const MAX_IMAGE_SIZE_MEGA_BYTE = 20;
export const MAX_IMAGE_SIZE_BYTE = MAX_IMAGE_SIZE_MEGA_BYTE * 1024 * 1024;

export const IMAGE_SIZE = {
  PROFILE: 128,
};

export const MAX_INPUT_LENGTH = {
  LINKNAME: 20,
};

export const KEMI_TOKEN_EXPIRES_TIME = 3600;

export const KEMI_THEME_ROOT_ID = 'theme-root';
export const KEMI_ROOT_TAG_ID = 'kemi-root';
export const KEMI_MODAL_ROOT_TAG_ID = 'kemi-modal-root';
export const KEMI_MOBILE_SIDEMENU_ROOT_TAG_ID = 'kemi-mobile-sidemenu-root';

export const ACCEPTABLE_IMAGE_TYPE = ['jpg', 'jpeg', 'png', 'gif', 'heic'];

export const QUERY = {
  BACK_URL: 'back-url',
  SIGNUP_MODAL: 'signup-modal',
  IMAGE_UPLOAD_MODAL: 'image-upload-modal',
  REQUEST_SAMPLE_MODAL: 'request-sample-modal',
  BUY_NOW_MODAL: 'buy-now-modal',
  SELECT_MAIN_SNS_MODAL: 'select-main-sns-modal',
  PRICE_DETAIL_MODAL: 'price-detail-modal',
  POST_CODE_MODAL: 'post-code-modal',
  PRODUCT_OPTION_MODAL: 'product-option-modal',
  OPEN_KEMI: 'open-kemi',
};

export const SNS_PREFIX = {
  EMAIL: '',
  INSTAGRAM: '@',
  YOUTUBE: '',
  NAVERBLOG: '',
  FACEBOOK: '',
  TWITTER: '@',
  TIKTOK: '@',
  ETC: '',
};

export const COMMON_MESSAGE = {
  NO_ERROR: undefined,
};

export const EVENT_TAG = {
  KEMI_PRODUCT_DETAIL: {
    SCREEN_OPEN_KEMIPRODUCT_DETAIL: 'screen_open_kemiproduct_detail', // used
  },
  KEMI_PAYMENT_DONE: {
    SCREEN_OPEN_KEMI_PAYMENT_DONE: 'screen_open_kemi_payment_done', // used
  },
  KEMI: {
    KEMI_CLICK_CARD: 'kemi_click_card', // used
  },
  GLOBAL_PAGE_OPEN: {
    VISIT_KEMI: 'visit_kemi', // used
  },
};

export const FACEBOOK_ERROR_CODE = {
  NO_FACEBOOK_ACCESS_TOKEN: 'NO_FACEBOOK_ACCESS_TOKEN',
  NO_FACEBOOK_PAGE: 'NO_FACEBOOK_PAGE',
  NO_CONNECTED_INSTAGRAM: 'NO_CONNECTED_INSTAGRAM',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  WRONG_TOKEN: 'WRONG_TOKEN',
};
